<script setup lang="ts">

  const defaultErrorTitle = 'システムエラー'
  const defaultErrorMessage = '予期しないエラーが発生しました。\n再度ログインをお願いします。\n解決しない場合は、管理者までお問い合わせください。'
  const props = defineProps<{
    errorTitle: string,
    errorMessage: string,
    errorCode: string,
    role: string,
  }>()

  const emits = defineEmits<{
    (e: 'close', isSystemError: boolean): boolean,
  }>()

  const errorTitle = props.errorTitle !== undefined && props.errorTitle !== ''  ? props.errorTitle : defaultErrorTitle
  const errorMessage = props.errorMessage !== undefined && props.errorMessage !== '' ? props.errorMessage : defaultErrorMessage
  const isSystemError = errorMessage === defaultErrorMessage
</script>
<template>
  <div
    class="
      z-common-modal-error
    "
  >
    <common-modal
      @close="emits('close', isSystemError)"
      :header-text="errorTitle"
      modal-margin-x="sp:mx-4 tab:mx-12"
      modal-height="h-auto"
      :role="props.role"
    >
      <template v-slot:body>
        <div
          class="
            break-words
            text-center
            sp:text-[4vw]
            tab:text-[2vw]
          "
        >
          <p
            v-for="e in errorMessage.split('\n')"
          >
          {{ e }}
          </p>
        </div>
        <div
          v-if="errorCode !== undefined && errorCode !== ''"
          class="
            break-words
            mt-2
            text-gray-400
            text-right
            text-xs
          "
        >
          [エラーコード: {{ props.errorCode }}]
        </div>
      </template>
      <template v-slot:control>
        <common-btn
          class="
            bg-black
            mx-4
            rounded-full
            text-gray-50
            w-full
          "
          btn-type="secondary"
          @click="emits('close', isSystemError)"
        >
          確認
        </common-btn>
      </template>
    </common-modal>
  </div>
</template>
